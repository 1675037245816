import React from 'react'
import {withGlobal} from '../../shared/app'
import {scrollTo} from '../../../lib/utils'
import {H1} from '../../misc/headings'

const Block = ({authed, flow, t, page, hasRole, Products, uiref = {}}) => {
  const unset = page.donation.state == 0
  const mode = unset ? '-unset' : ''

  const {donation, stats} = page
  const {beneficiary} = donation

  const onClick = () => {
    authed
      ? unset
        ? flow.set('ft-donation-settings', {uiref})
        : flow.set('ft-donation', {uiref})
      : scrollTo()
  }
  if (!Products.Donation.isActive(page, hasRole)) return null

  return (
    <div className='card'>
      <div className='card-body'>
        <H1>{t(`card-ft-donation${mode}.h1`)}</H1>

        <div className='d-flex flex-column flex-lg-row align-items-center'>
          <div className='z1'>
            <img className='mb-3 img-fluid' src={t('card-ft-donation.icon')} />
          </div>
          <div className='z2'>
            {!mode && (
              <React.Fragment>
                <h3>{t(`card-ft-donation.title-${beneficiary}`, page)}</h3>
                {stats.donations.count > 0 && <p>{t(`card-ft-donation.text-stats`, page)}</p>}
                <div className='text-light pre-line'>{donation.message}</div>
              </React.Fragment>
            )}

            {mode && (
              <React.Fragment>
                <h3>{t(`card-ft-donation${mode}.title`, page)}</h3>
                <div className='text-light pre-line'>
                  {donation.message || t(`card-ft-donation${mode}.text`, page)}
                </div>
              </React.Fragment>
            )}

            <button className='btn btn-primary mt-3' onClick={() => onClick()}>
              {t(`card-ft-donation${mode}.cta`)}
            </button>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .z1 {
            flex: 1;
          }
          .z2 {
            flex: 2;
          }

          img {
            width: 170px;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
