import {CardElement, StripeProvider, Elements} from 'react-stripe-elements'

export const timeout = (ts = 1000) => new Promise((solve) => setTimeout(solve, ts))

export const stripeErrorHandler = (err) => {
  if (err) {
    if (typeof state !== 'undefined')
      state.trackEvent('stripe:error', {error: err.message || err}, 'profile')

    $(`.stripe-error`)
      .removeClass('d-none')
      .html(err.message || err)
      .show()
  } else $(`.stripe-error`).addClass('d-none').html('').hide()
}

const handleAction = async (res, stripe, apiEndpoint, ctx) => {
  const {error, paymentIntent} = await stripe.handleCardAction(res.payment_intent_client_secret)

  if (error) return {error: {message: error.message}}
  else {
    const serverResponse = await (
      await fetch(`${apiEndpoint}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({pi_id: paymentIntent.id, ctx})
      })
    ).json()

    return handleServerResponse(serverResponse, stripe, apiEndpoint)
  }
}

const handleServerResponse = async (res, stripe, apiEndpoint, ctx) => {
  if (res.error) return {error: res.error}
  else if (res.requires_action) return await handleAction(res, stripe, apiEndpoint, ctx)
  else return res
}

export const StripeInput = ({
  stripe,
  onChange = ({err}) => stripeErrorHandler(err),
  onReady = () => {},
  locale
}) => {
  const applyStyle = () => ({
    style: {
      base: {
        color: '#222222',
        fontSize: '16px',
        fontWeight: '100',
        fontFamilly: 'opensans, sans-serif',
        '::placeholder': {color: '#9E9E9E'}
      },
      invalid: {color: '#FF8F60', iconColor: '#FF8F60'}
    }
  })

  return (
    <StripeProvider stripe={stripe}>
      <Elements locale={locale} key={locale}>
        <CardElement onReady={onReady} onChange={onChange} {...applyStyle()} />
      </Elements>
    </StripeProvider>
  )
}

export const PayToEndpoint = async (
  stripe,
  cardElement,
  apiEndpoint,
  paymentOptions = {},
  ctx = {}
) => {
  const {error, paymentMethod} = await stripe.createPaymentMethod(
    'card',
    cardElement,
    paymentOptions
  )

  if (error) return stripeErrorHandler(error) && {error}
  else {
    try {
      const serverResponse = await (
        await fetch(`${apiEndpoint}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({pm_id: paymentMethod.id, ctx})
        })
      ).json()

      const result = await handleServerResponse(serverResponse, stripe, apiEndpoint, ctx)
      // console.log('PayToEndpoint result', result)

      result.error && stripeErrorHandler(result.error)
      return result
    } catch (e) {
      console.log('PayToEndpoint catch', e)
    }
  }
}

const bytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2)

export const StripeUpload = async ({file, pk, mock, purpose = 'identity_document', maxSize = 5}) =>
  new Promise(async (solve, ject) => {
    try {
      const fileSize = bytesToMB(file.size)
      if (fileSize > maxSize) {
        let err = `Maximum file size: ${maxSize}Mo - ${file.name} is ${fileSize}Mo`
        alert(err)
        return ject(err)
      }

      const formData = new FormData()
      formData.append('purpose', purpose)
      formData.append('file', file)

      if (mock) {
        await timeout(2000)
        return solve({id: 'file_mock'})
      }

      const result = await (
        await fetch(`https://uploads.stripe.com/v1/files`, {
          method: 'post',
          body: formData,
          headers: {Authorization: `Bearer ${pk}`}
        })
      ).json()

      solve(result)
    } catch (e) {
      ject(e)
    }
  })

export const StripeFilePond = (purpose, pk, mock) => ({
  process: (fieldName, file, metadata, load, error, progress, abort) => {
    const url = `https://uploads.stripe.com/v1/files`
    const xhr = new XMLHttpRequest()
    const formData = new FormData()

    xhr.open('POST', url, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.setRequestHeader('Authorization', `Bearer ${pk}`)

    xhr.upload.addEventListener('progress', (e) => {
      progress(e.lengthComputable, e.loaded, e.total)
    })

    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) {
        return
      }

      if (xhr.status >= 200 && xhr.status < 300) {
        const response = JSON.parse(xhr.responseText)
        load(response.id)
        return
      }

      error(`Error: ${xhr.statusText}`)
    }

    formData.append('purpose', purpose)
    formData.append('file', file)

    if (mock) {
      load('file_mock')
    } else {
      xhr.send(formData)
    }
    return {
      abort: () => {
        abort()
        xhr.abort()
      }
    }
  }
})
